$primary-light: #fea4ad;
$primary: #dd4f5d;
$primary-dark: #96222e;
$error: red;

:root {
    --inset-top: env(safe-area-inset-top);
}

@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "@fortawesome/fontawesome-free/css/all.css";
@import "./VideoLib/index.scss";
@import "react-phone-number-input/style.css";
@import "react-image-crop/src/ReactCrop.scss";

@font-face {
    font-family: "Digital";
    src: local("Digital"), url("../public/fonts/digital.ttf") format("truetype");
}

html,
body {
    font-family: "Chivo" !important;
    background-color: #333 !important;
    color: white;
    overscroll-behavior: none;
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: "Bakbak One" !important;
    }
}
.font-regular {
    font-family: "Chivo" !important;
}
.btn.btn-outline-primary.text-white {
    border-color: white;
    &:hover {
        background-color: white;
        color: #333 !important;
    }
    &.disabled,
    &:disabled {
        background-color: transparent !important;
        color: #fff !important;
    }
}
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
    color: white !important;
}
.btn-link {
    text-decoration: none;
    &.underlined {
        text-decoration: underline;
        text-underline-offset: 5px;
    }
}
.MuiTypography-root.MuiTypography-inherit.MuiLink-root.btn.btn-link {
    text-decoration: none;
}
.btn.btn-outline-light.gray-btn {
    background-color: #666;
    border-color: #666;
    &:hover {
        background-color: white;
        color: #333;
    }
    &.disabled,
    &:disabled {
        background-color: #666;
        &:visited,
        &:active {
            color: white;
        }
    }
}
.btn-outline-secondary {
    color: white;
    border-color: white;
}
.btn.btn-link.text-white {
    color: #ccc !important;
    &:hover {
        color: white !important;
    }
}
.btn.btn-link.text-gray {
    &:hover {
        color: white !important;
    }
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    pointer-events: all;
    cursor: not-allowed;
}
@media only screen and (max-width: 600px) {
    button.btn-outline-light:hover {
        background-color: transparent;
        color: white;
    }
}
.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $primary !important;
    span {
        color: $primary !important;
    }
}
label.Mui-focused {
    color: $primary !important;
}
.MuiIconButton-root.Mui-disabled {
    pointer-events: all;
    cursor: not-allowed;
}
.MuiIconButton-colorPrimary {
    .MuiSvgIcon-root {
        color: $primary;
    }
}
.MuiButtonBase-root.MuiIconButton-root {
    &.disabled,
    &:disabled {
        cursor: not-allowed;
    }
}
.container-max-width {
    max-width: 840px;
}
.container-sm {
    max-width: 660px;
}
.hero-container,
.why-communicate-container,
.collaborate-container {
    overflow: hidden;
    color: #333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
}
.hero-video-shadow {
    box-shadow: 0 0 20px rgba(115, 57, 121, 1);
}
.use-cases.player {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    &.mobile {
        transform: translateX(-25%);
    }
}
.hero-container,
.why-communicate-container {
    position: relative;
    .player {
        position: absolute;
        left: 0;
        width: 100%;
        &.mobile {
            transform: translateX(-25%);
        }
    }
    .container {
        z-index: 10;
        position: relative;
    }
}
.why-communicate-container {
    .player {
        left: 50%;
        transform: translateX(-50%);
    }
}
.translucent-container {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
}
.landing-list {
    padding: 0.5rem;
    li {
        font-size: 1.3rem;
    }
}
@media only screen and (max-width: 400px) {
    .landing-list {
        li {
            font-size: 1.1rem;
        }
    }
}
.text-error {
    color: red;
}
.text-lighter {
    color: #bbb;
}
.text-gray {
    color: #999;
}
.text-dark-gray {
    color: #666;
}
.text-disabled {
    color: #999 !important;
}
.white-bg {
    background-color: white !important;
}
.text-right {
    text-align: right;
}
.text-underline {
    text-decoration: underline;
}
.capitalize {
    text-transform: capitalize;
}
@media only screen and (max-width: 1070px) {
    .hero-container,
    .why-communicate-container {
        .player {
            width: auto;
            height: 100%;
        }
    }
}
.usage-list {
    list-style-type: none;
    padding: 0;
    .usage-list-item {
        background-color: #4a4a4a;
        padding: 20px;
        border-radius: 4px;
        margin: 10px 0;
        max-width: 600px;
        white-space: break-spaces;
        &:nth-child(even) {
            background-color: #222222;
            margin-left: auto;
        }
    }
}
svg {
    .st0 {
        fill: #ffffff;
    }
    .st1 {
        fill: #000000;
    }
    .st2 {
        display: none;
        fill: #871844;
    }
    .st3 {
        display: none;
        fill: #dddddd;
    }
    .st4 {
        display: none;
        fill: #ff3d8b;
    }
    .st5 {
        display: none;
        fill: none;
        stroke: #000000;
        stroke-miterlimit: 10;
    }
}
.collaborate-container {
    overflow: hidden;
    background-image: none;
    position: relative;
    .text-container {
        position: absolute;
        width: 400px;
        top: 41%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    img {
        border-radius: 5px;
        position: absolute;
    }
    .image-1 {
        top: 0;
        left: 2%;
        width: 15%;
        min-width: 80px;
    }
    .image-2 {
        top: 8%;
        left: 12%;
        width: 18%;
        min-width: 80px;
        transform: translate(0, 12%);
    }
    .image-3 {
        bottom: 0;
        left: -30px;
        width: 19%;
        min-width: 80px;
    }
    .image-4 {
        top: 0;
        right: 5%;
        width: 10%;
        min-width: 60px;
    }
    .image-5 {
        top: 11%;
        right: 12%;
        width: 20%;
        min-width: 80px;
    }
    .image-6 {
        bottom: 5%;
        right: 5%;
        width: 17%;
        min-width: 80px;
    }
    .image-7 {
        bottom: 0;
        left: 50%;
        width: 20%;
        min-width: 120px;
        transform: translate(-50%, 50%);
    }
}
@media only screen and (max-width: 600px) {
    .hero-container {
        min-height: 330px;
    }
    .why-communicate-container {
        min-height: 270px;
    }
    .collaborate-container .text-container {
        width: 80%;
        top: 55%;
    }
}
.opinion-container {
    background: $primary;
}
.authenticity-seal {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    &.full-width {
        left: 0%;
        right: 0%;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 1rem;
        transform: none;
    }
    img {
        max-width: 100px;
        opacity: 0.6;
    }
    .ring {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .ring img {
        opacity: 1;
    }
}
@media only screen and (max-width: 500px) {
    .authenticity-seal {
        bottom: calc(40px + env(safe-area-inset-top) + var(--warning-strip-height, 0px));
    }
}
li.no-bullet {
    list-style: none;
    background-image: none;
    background-repeat: none;
    background-position: 0;
}
.use-case-page-video {
    .previous-btn,
    .next-btn {
        display: none;
    }
    .video-end-message-container {
        opacity: 0.8;
    }
    .authenticity-seal {
        max-width: 60px;
        bottom: 3px;
        img {
            max-width: 60px;
        }
    }
}
img.hover-zoom {
    transition: all 0.3s ease 0s;
}
img.hover-zoom:hover {
    box-shadow: 0 0 20px #444 !important;
    transform: scale(1.6) translate(20%, 10px);
}
.text-primary {
    color: $primary;
    svg {
        fill: $primary;
    }
}
.text-primary-dark {
    color: $primary-dark;
    svg {
        fill: $primary-dark;
    }
}
.bg-primary {
    background-color: $primary;
}
.bg-darkest {
    background-color: black;
}
.triangle-down {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid #222;
}
@media only screen and (min-width: 500px) {
    .pricing-card {
        transition: all 100ms ease-in-out;
        &:hover {
            transform: scale(1.01);
            .usage-video-power-section {
                box-shadow: 0 0 20px #555 !important;
            }
        }
    }
}
.pricing-label {
    color: #12a912;
    .currency {
        font-size: 1.5em;
        margin-right: 0.5em;
    }
    .price {
        font-size: 3rem;
    }
    .coming-soon {
        font-size: 2.5em;
        line-height: 72px;
    }
    .sub {
        font-size: 1.5em;
    }
}
.rounded-lg {
    border-radius: 1rem;
}
.pricing-feature-details {
    border-radius: 1rem;
    background: #333;
    height: 100%;
    &:hover {
        background: #292929;
    }
}
.usage-video-power-section {
    max-width: 800px;
    background: black;
    border-radius: 20px;
    overflow: hidden;
    margin: 20px;
}
a.MuiTypography-root {
    color: $primary;
    text-decoration-color: rgba(221, 79, 93, 0.4);
}
.remove-padding {
    .MuiDialogContent-root {
        padding: 0px !important;
    }
}
.dialog-m-2 .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper {
    margin: 1rem;
    min-width: 320px;
}
.dialog-m-0 .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper {
    margin: 0;
    min-width: 320px;
}
.dialog-p-0 .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper {
    padding: 0;
    .MuiDialogContent-root {
        padding: 0;
    }
}
.waitlist-success-dialog {
    .MuiDialog-container.MuiDialog-scrollPaper {
        width: 100%;
        .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper {
            width: 100%;
        }
    }
}
.MuiDialog-root.MuiModal-root.overflow-hidden .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    overflow: hidden;
}
.login-popup .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper {
    margin: 0;
    min-width: 320px;
    .MuiDialogContent-root {
        padding: 20px;
    }
}
.white-background {
    .MuiDialogContent-root {
        background-color: white;
    }
}
.MuiDialogActions-root.MuiDialogActions-spacing {
    padding: 8px 8px 20px 8px;
    button {
        min-width: 100px;
        &.MuiIconButton-root {
            min-width: unset;
        }
    }
    &.no-min-width {
        button {
            min-width: 40px;
        }
    }
}
.MuiDrawer-root.MuiDrawer-modal {
    .MuiBackdrop-root.MuiModal-backdrop {
        background-color: transparent;
    }
}
.MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    background-color: transparent;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    color: #fff;
    background-color: #555;
    &:before {
        background-color: #555;
    }
    &.MuiDialog-paper {
        background-color: #080808;
    }
    .MuiButtonBase-root.MuiMenuItem-root,
    .MuiButtonBase-root.MuiListItemButton-root {
        color: #fff;
        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
        color: #fff;
    }
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    &.MuiAccordion-root {
        box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.15);
        &.rounded-accordion {
            overflow: hidden;
            border-radius: 24px;
        }
        .MuiAccordionSummary-root {
            background-color: #494949;
            &.Mui-expanded {
                border-bottom: 1px solid #555;
            }
        }
        .MuiCollapse-root {
            background-color: #555;
        }
    }
    &.MuiAccordion-root.light {
        border: 1px solid #d9d9d9;
        .MuiAccordionSummary-root {
            background-color: #d9d9d9;
            color: #000;
            &.Mui-expanded {
                border-bottom: 1px solid #eee;
            }
        }
        .MuiCollapse-root {
            background-color: #f9f9f9;
            color: #000;
        }
        .MuiAccordionSummary-expandIconWrapper {
            color: #000;
        }
    }
    &.MuiAccordion-root.dark {
        .MuiCollapse-root {
            background-color: #333;
        }
    }
}
.security-settings-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded,
.cookies-disabled-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background-color: #000 !important;
}
.faq-resources {
    padding-top: 1.5rem;
}
@media only screen and (max-width: 992px) {
    .faq-resources {
        padding-top: 0;
    }
}
.fullscreen-video-popup {
    width: 100%;
}
.login-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background-color: #000 !important;
}
.embedded-browser-popup {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        background-color: #000 !important;
    }
}
.verify-password-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background-color: #000 !important;
}
.PrivateSwipeArea-root {
    z-index: 999 !important;
}
.MuiSvgIcon-root.font-large {
    font-size: 70px;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    text-transform: none;
    &.capitalize {
        text-transform: capitalize;
    }
}
.MuiPopper-root {
    z-index: 1100;
}
.MuiList-root.MuiMenu-list .MuiDivider-root {
    border-color: white;
}
hr.MuiDivider-root {
    border-bottom-width: 1px;
    border-color: white;
}
hr.darker {
    color: #999;
}
.break-word {
    word-wrap: break-word;
}
.break-spaces {
    white-space: break-spaces;
}
.break-word-pre-wrap {
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap; /* Opera <7 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE */
}
.limit-width {
    max-width: 120px;
}
.show-controls-on-hover {
    .controls {
        opacity: 0;
    }
    &:hover {
        .controls {
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 400px) {
    .limit-width {
        max-width: 90px;
    }
}
.break-all {
    word-wrap: break-all;
}
.features-icon {
    max-height: 70px;
}
nav {
    transition: all 400ms linear;
}
nav.dark-theme,
.nav-bar-theme {
    background: #4a4a4a;
    color: white;
}
.navbar .btn-link {
    color: white;
    &:hover {
        color: $primary;
    }
}
.nav-background {
    background-color: #333;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.contacts-chip-list {
    min-height: 44px;
    max-height: 120px;
    overflow: auto;
    gap: 5px;
    .last-child {
        overflow: hidden;
    }
    .label {
        border-radius: 4px;
    }
    &.dragging .label {
        background: $primary-light;
    }
}
.draggable {
    cursor: grab !important;
}
.create-flow-back-button {
    position: absolute;
    top: 2px;
    left: -80px;
}
.copy-paste-checkbox .MuiTypography-body1.MuiFormControlLabel-label {
    font-size: 0.9rem;
}
.save-draft-checkbox {
    .MuiTypography-root.MuiFormControlLabel-label {
        font-size: 0.95rem;
    }
}
.close-popup-btn.MuiButtonBase-root.MuiIconButton-root {
    position: absolute;
    top: 50px;
    right: 20px;
    color: #333;
}
@media only screen and (max-width: 600px) {
    .save-draft-checkbox {
        .MuiTypography-root.MuiFormControlLabel-label {
            font-size: 0.9rem;
        }
    }
    .close-popup-btn.MuiButtonBase-root.MuiIconButton-root {
        top: 70px;
    }
}
@media only screen and (max-width: 400px) {
    .save-draft-checkbox {
        .MuiTypography-root.MuiFormControlLabel-label {
            font-size: 0.8rem;
        }
    }
}
.countdown-timer {
    font-size: 20px;
    background: rgba(0, 0, 0, 0.8);
}
#message-input,
#teleprompter-container {
    display: block;
    text-align: left;
    border-radius: 4px 0 0 4px;
    line-height: 24px;
    width: 100%;
    overflow: auto;
    .teleprompter-large-text {
        white-space: break-spaces;
        word-wrap: break-word;
        font-size: 250%;
        line-height: normal;
        color: #999;
        .highlighted {
            color: #fff72c;
        }
    }
    .teleprompter-regular-text {
        color: #fff72c;
        white-space: break-spaces;
    }
    &.dark {
        background-color: transparent;
    }
    &.left-aligned {
        text-align: left;
    }
    &.compact-width {
        max-width: 480px;
    }
}
@media only screen and (min-width: 700px) {
    #message-input,
    #teleprompter-container {
        padding-right: 40px !important;
        width: calc(100% + 20px);
        cursor: auto;
    }
}
.suggestion-box-record-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: -300px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.show-focus {
    padding: 4px;
    border-radius: 0;
    border-bottom: 1px solid transparent !important;
    &.show-border-bottom {
        border-bottom: 1px solid #777 !important;
        &.has-error {
            border-bottom: 1px solid red !important;
        }
    }
}
.Toastify__toast-container {
    width: auto;
    max-width: 800px;
    min-width: 300px;
}
@media (max-width: 768px) {
    .Toastify__toast-container {
        width: 100%;
    }
}
.object-fit-contain {
    object-fit: contain;
}
.object-fit-cover {
    object-fit: cover;
}
.show-focus:focus {
    border-bottom: 1px solid white !important;
}
.input-editable {
    position: relative;
    input {
        color: white;
        background: transparent;
        border: none;
        &.h4,
        &.h5 {
            margin-bottom: 0;
        }
        &:focus-visible {
            outline: none;
        }
    }
}
.no-margin-para p {
    margin-bottom: 0 !important;
}
.thinner-select {
    background: #111;
    .MuiSelect-select.MuiSelect-outlined {
        padding: 0 30px 0 10px;
    }
}
.warning-strip {
    background-color: #e1be42;
    &.about-to-expire {
        background-color: #f39141;
    }
    &.expired {
        background-color: #e96070;
    }
    color: #000;
}
.message-actions-container {
    padding-left: 5px;
    border-radius: 0 0 0 4px;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    &.mobile {
        top: 10px;
    }
}
.top-message-arrow {
    position: absolute;
    top: 5px;
    right: 30px;
}
.bottom-message-arrow {
    position: absolute;
    bottom: 5px;
    right: 30px;
}
.fixed-bottom {
    background-color: #333;
    z-index: 1190;
    -webkit-box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
}
.bottom-bar {
    background-color: #333;
    -webkit-box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
}
.fixed-top {
    z-index: 1210;
}
.use-case-card {
    border: 1px solid #f0f0f0;
    .primary-image {
        object-fit: cover;
        width: 100%;
        height: 240px;
    }
}
@media only screen and (min-width: 600px) {
    .use-case-card.larger-layout {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        .larger-layout {
            width: 50%;
        }
        .primary-image {
            height: auto;
        }
    }
}
.ticker-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 160px;
    img {
        width: 100%;
        max-width: 250px;
        max-height: 150px;
        object-fit: contain;
    }
    img:last-of-type {
        padding-left: 0;
    }
    .image {
        padding: 0 30px;
        width: 250px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .wrapper {
        position: absolute;
        display: flex;
    }
    section {
        display: flex;
        animation: swipe 20s linear infinite backwards;
    }
    @-webkit-keyframes swipe {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        100% {
            -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
        }
    }
    @keyframes swipe {
        0% {
            -ms-transform: translate(0, 0);
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        100% {
            -ms-transform: translate(-100%, 0);
            -webkit-transform: translate(-100%, 0);
            -moz-transform: translate(-100%, 0);
            -o-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
        }
    }
}
.svg-shadow {
    -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.7));
}
.time-saving {
    background: #303030;
    border-radius: 10px;
    padding: 2px 10px 0;
    font-size: 0.9rem;
    line-height: 20px;
}
.digital {
    font-family: "Digital";
}
.usage-team-member {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 30px;
    .member {
        display: block;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
        border: 4px solid transparent;
    }
    .member-container {
        width: 180px;
        height: 180px;
        &:hover {
            .play-icon {
                -webkit-box-shadow: inset 0px 0px 0px 4px #fff;
                -moz-box-shadow: inset 0px 0px 0px 4px #fff;
                box-shadow: inset 0px 0px 0px 4px #fff;
                &.white-icon {
                    -webkit-box-shadow: inset 0px 0px 0px 4px #fff;
                    -moz-box-shadow: inset 0px 0px 0px 4px #fff;
                    box-shadow: inset 0px 0px 0px 4px #fff;
                }
            }
        }
        &.active {
            cursor: pointer;
            &:hover {
                .member {
                    border: 4px solid #fff;
                }
            }
        }
    }
    .play-icon,
    .stop-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 40px;
        height: 40px;
        transform: translate(-10px, -10px);
        border-radius: 50%;
        background: #cd5960;
        -webkit-box-shadow: inset 0px 0px 0px 4px transparent;
        -moz-box-shadow: inset 0px 0px 0px 4px transparent;
        box-shadow: inset 0px 0px 0px 4px transparent;
        &:hover {
            -webkit-box-shadow: inset 0px 0px 0px 4px #fff;
            -moz-box-shadow: inset 0px 0px 0px 4px #fff;
            box-shadow: inset 0px 0px 0px 4px #fff;
        }
    }
    .video-preview {
        top: 0;
        left: 0;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        overflow: hidden;
        video {
            min-width: 180px;
            min-height: 180px;
            object-fit: cover;
        }
    }
}
.sales-form {
    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
        padding: 0.5rem;
    }
}
.number-breadcrumb {
    padding: 2px 5px;
    width: 34px;
    background: #4a4a4a !important;
    color: #777 !important;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 4px;
    text-align: center;
    border-radius: 50% !important;
    cursor: not-allowed;
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
    &.elapsed,
    &.clickable {
        background: #777 !important;
        color: white !important;
    }
    &.current {
        opacity: 1;
        background: $primary !important;
        color: white !important;
        cursor: not-allowed;
    }
}
.my-account {
    .my-account-nav {
        .nav-links {
            .link {
                font-size: 0.9rem;
                cursor: pointer;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}
.account-info,
.public-profile {
    background: transparent;
    color: white;
    input {
        background: transparent;
        color: white;
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #999;
            opacity: 1; /* Firefox */
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #999;
        }
        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #999;
        }
    }
    .line-item {
        margin-bottom: 1.5rem;
    }
    .profile-avatar .MuiAvatar-root.MuiAvatar-circular {
        width: 160px;
        height: 160px;
        text-transform: uppercase;
        font-style: normal;
        background-color: white;
    }
    .avatar-image {
        font-size: 4rem;
    }
    .close-icon {
        top: 10px;
        right: 10px;
        &.mobile {
            right: 81px;
        }
    }
    .managed-by-icon {
        border: 1px solid white;
        border-radius: 5px;
        padding: 5px;
        background-color: white;
    }
}
.tall-cover {
    .press-page-item {
        .press-item-image {
            height: 320px;
            object-position: top;
        }
        .press-item-hero {
            padding-top: 3rem;
        }
    }
}
.press-page-item {
    position: relative;
    overflow: hidden;
    background-color: #333333;
    box-shadow: 0 0 20px #444 !important;
    border-radius: 1.5rem;
    height: 100%;
    &:hover {
        background-color: #444;
        box-shadow: 0 0 20px #555 !important;
    }
    &.clickable {
        cursor: pointer;
        &:hover {
            .title {
                text-decoration: underline;
            }
        }
    }
    .press-item-hero {
        padding: 1rem;
        left: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
    }
    .press-item-image {
        height: 260px;
        background: black;
    }
    .press-title {
        font-size: 1.5rem;
    }
}
.phone-input {
    input {
        background: transparent;
        color: white;
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        &::placeholder {
            padding-left: 10px;
        }
    }
    &.text-white {
        input {
            color: white;
            &:focus {
                color: white;
            }
        }
    }
}
.message-container {
    transition: all 1s ease-in-out; /* configure the transition*/
    height: 100%;
    &.mobile {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .arrow-top,
    .arrow-bottom {
        position: absolute;
        color: #555;
        right: -34px;
        cursor: not-allowed;
        &.active {
            color: #888;
            cursor: pointer;
            &:hover {
                color: #ccc;
            }
        }
    }
    .arrow-top {
        top: -12px;
        &.teleprompter {
            top: 0;
        }
    }
    .arrow-bottom {
        bottom: -20px;
    }
}
.form-control {
    background: transparent;
    color: white;
    &.error {
        border-color: $primary;
        color: $primary;
    }
    &::placeholder {
        color: #999;
        opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: #999;
    }
    &:disabled {
        background-color: transparent;
        color: #999;
        cursor: not-allowed;
    }
    &:focus {
        background: transparent;
        color: white;
    }
}
.bordered-container {
    border: 1px solid white;
    border-radius: 4px;
}
.position-absolute.bottom-right {
    bottom: 0;
    right: 0;
}
.position-absolute.bottom {
    bottom: 0;
    left: 0;
}
.MuiCircularProgress-colorPrimary {
    color: white !important;
}
.MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
    background-color: $primary-dark;
}
.share-icons-nav {
    position: absolute;
    top: 0;
    height: 100%;
    &.arrow-left {
        left: 0;
        background: linear-gradient(90deg, #333, transparent);
    }
    &.arrow-right {
        right: 0px;
        background: linear-gradient(270deg, #333, transparent);
    }
    .icon {
        margin: 22px 10px;
        background: #dd4f5d;
        border-radius: 50%;
    }
}
.how-to-whitelist {
    img.step-image {
        display: block;
        max-width: 100%;
        max-height: 300px;
        margin: 10px auto 20px;
    }
}
.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary {
    background-color: $primary-light;
}
.MuiSlider-markLabel {
    color: white;
}
.MuiChip-label {
    color: #333;
}
.text-white {
    .MuiChip-label {
        color: white;
    }
    svg {
        fill: white;
    }
}
.MuiChip-root {
    &.gray-bg-chip {
        background-color: #4a4a4a;
        .MuiChip-label {
            color: #ededed;
        }
        .MuiChip-deleteIcon svg {
            color: #ededed;
        }
        &.text-white .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.MuiChip-avatar {
            background-color: #eee;
            color: #333;
        }
    }
    &.text-dark-gray {
        color: #666;
        border: 1px solid #ccc;
    }
    &.selected {
        background-color: $primary-light !important;
    }
    .MuiButtonBase-root.MuiIconButton-root {
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            color: #757575;
        }
        &.Mui-disabled {
            .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
                color: #ccc;
            }
        }
    }
}
.Mui-disabled {
    pointer-events: all !important;
    cursor: not-allowed !important;
}
.MuiTabs-indicator {
    background-color: $primary !important;
}
.MuiTabs-scrollButtons.MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled {
    opacity: 0.3;
}
.navbar-tabs-container {
    margin-bottom: -1.3rem;
}
button.MuiButtonBase-root.MuiTab-root {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 1rem;
}
.MuiButtonBase-root.color-primary {
    color: $primary;
}
.white-bg.MuiBox-root {
    width: 60px;
    left: calc(50% - 30px);
}
.MuiBadge-badge.MuiBadge-colorInfo {
    background-color: #666;
    color: white;
}
.MuiButtonBase-root.MuiTab-root {
    padding: 12px 16px 12px 8px;
}
.white-space-wrap {
    white-space: pre-wrap;
    display: inline-block;
}
[contenteditable] {
    outline: 0px solid transparent;
    &:hover,
    &:focus {
        color: white !important;
    }
}
[contenteditable]:empty:before {
    content: attr(aria-placeholder);
    position: absolute;
    color: gray;
    background-color: transparent;
}
.badged-tabs {
    .MuiTabs-indicator {
        background-color: transparent !important;
    }
    .MuiButtonBase-root.MuiTab-root.Mui-selected {
        color: $primary !important;
    }
    .MuiBadge-dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
    }
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
        text-decoration: underline;
        text-underline-offset: 8px;
        text-decoration-thickness: 3px;
    }
}
.file-drop-container {
    cursor: pointer;
}
.conversations {
    .ai-answer {
        align-items: start;
        border-radius: 0.5rem;
        & > div {
            white-space: break-spaces;
        }
    }
    .user-prompt {
        margin-top: 1rem;
        justify-content: end;
        & > div {
            max-width: 80%;
            border-radius: 1rem 0 1rem 1rem;
            background-color: #222;
        }
    }
}
.profile-image-icon {
    top: 16%;
    left: 54%;
    background: #777;
    border: 1px solid #fff;
    border-radius: 50%;

    &.mobile {
        top: 24%;
        left: 56%;
    }
    &:hover {
        background: red;
    }
}
.close-icon {
    position: absolute !important;
    top: 10px;
    color: $primary;
    right: 5px;
    cursor: pointer;
}
.add-photo-icon:hover {
    background-color: transparent !important;
}
.search-bar {
    width: auto !important;
    .MuiInputBase-root.MuiOutlinedInput-root {
        border-radius: 24px;
        background: black;
        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $primary;
        }
    }
    .MuiFormLabel-root.MuiInputLabel-root {
        line-height: 0.8em;
        &.Mui-focused {
            color: $primary;
        }
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
        height: 2rem;
        padding: 6.5px 14px !important;
    }
}
.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader {
    background-color: #333;
    font-weight: bold;
}
.MuiSkeleton-root.MuiSkeleton-wave {
    &:after {
        -webkit-animation: animation-wiooy9 1s linear 0.1s infinite;
        animation: animation-wiooy9 1s linear 0.1s infinite;
    }
}
td.label-col {
    width: 30px;
}
.post.border.border-primary {
    border-width: 5px !important;
}
.logo-nav {
    max-width: 120px;
}
.logo-footer {
    max-width: 150px;
    max-height: 55px;
}
.drag-handle {
    position: absolute !important;
    top: 8px;
    right: 40px;
    transform: rotateY(180deg);
}
.vertical-divider {
    background-color: #999;
    width: 1px;
}
textarea {
    vertical-align: top;
    background-color: transparent;
    color: white;
}
textarea.dark,
input.dark {
    color: white;
    border: none;
    outline: none;
    resize: none;
    &.text-light {
        color: rgba(255, 255, 255, 0.7) !important;
    }
}
input.has-error {
    border-color: red;
}
.MuiInputBase-root.MuiInput-root.text-light {
    &:before {
        border-bottom: none;
    }
    .MuiNativeSelect-select {
        color: rgba(255, 255, 255, 0.7);
    }
}
.recommended-info {
    color: $primary;
    width: 100%;
    text-align: center;
    padding: 0.5rem 0 0 0;
    background: #111;
}
.suggestion-button {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 10px;
    width: max-content;
    transform: translate(-50%, 0);
    height: 40px;
}
.login-features {
    i {
        color: $primary;
    }
}
.h-100 {
    height: 100%;
}
#login-password-input {
    &input::-webkit-textfield-decoration-container {
        opacity: 0;
    }
    &input::-webkit-caps-lock-indicator {
        opacity: 0;
    }
    &input::-webkit-credentials-auto-fill-button {
        opacity: 0;
    }
    &input::-ms-clear {
        opacity: 0;
    }
    &input::-ms-reveal {
        opacity: 0;
    }
}
.min-width-80px {
    min-width: 80px;
}
.min-width-100px {
    min-width: 100px;
}
.min-width-140px {
    min-width: 140px;
}
.controls {
    display: flex;
    justify-content: end;
}
.clickable {
    cursor: pointer;
}
.click-disabled {
    cursor: not-allowed;
}
.footer-logo-text {
    max-width: 180px;
}
@media screen and (min-width: 380px) {
    .footer-logo-text {
        line-height: 50px;
    }
}
.social-btn-icon {
    height: 24px;
}
.opacity-50 {
    opacity: 0.5;
}
.use-cases-page,
.landing-page,
.about-us-page,
.pricing-page {
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: "SF Pro Display", sans-serif !important;
    }
}
.landing-page {
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: "SF Pro Display", sans-serif !important;
        font-weight: 900;
    }
}
.text-large {
    font-size: 2rem;
}
.text-small {
    font-size: 0.75rem;
}
.text-shadow {
    text-shadow: 0.05em 0.05em 0.1em rgba(0, 0, 0, 0.5);
}
.login-dialog {
    max-width: 400px;
    background: transparent;
    color: white;
    input {
        background: transparent;
        color: white;
        padding-right: 40px;
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #999;
            opacity: 1; /* Firefox */
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #999;
        }
        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #999;
        }
    }
}
.about-us-members {
    width: 33.33%;
}
.verify-dialog {
    max-width: 450px;
}
.otp-input {
    &:hover {
        border-bottom: 3px solid #707070 !important;
    }
    &:active,
    &:focus {
        border-bottom: 3px solid $primary !important;
    }
    &.error {
        border-radius: 5px !important;
        border: 3px solid $error !important;
    }
    .PhoneInputInput {
        border: none !important;
    }
}
.mfa-popup .MuiDialogContent-root {
    margin: unset !important;
}
.mfa-buttons {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
}
.horizontal-scrollable-list {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        cursor: pointer;
    }
}
@media only screen and (min-width: 400px) {
    .horizontal-scrollable-list {
        justify-content: center;
    }
}
.left-scroll-indicator,
.right-scroll-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    padding: 35px 10px;
    .MuiSvgIcon-root {
        color: white;
    }
}
.left-scroll-indicator {
    left: 0;
    background-image: linear-gradient(to left, rgba(51, 51, 51, 0), rgba(51, 51, 51, 1));
}
.right-scroll-indicator {
    right: 0;
    background-image: linear-gradient(to right, rgba(51, 51, 51, 0), rgba(51, 51, 51, 1));
}
.social-buttons button {
    border: 1px solid #ddd;
    color: white;
    border-radius: 7px !important;
    background-color: transparent;
    margin-right: 10px;
    line-height: 1rem;
    font-weight: normal;
    font-size: 1.1rem;
    &:hover {
        border: 1px solid $primary;
        cursor: pointer;
    }
    img {
        width: 30px;
        height: 30px;
    }
    .google-button,
    .apple-button {
        padding: 10px;
        margin: 0.36rem !important;
        padding: 15px;
        border: 1px solid $primary;
    }
    label {
        &:hover {
            cursor: pointer;
        }
    }
}
.image-list {
    .thumbnail-option {
        height: 100px;
        cursor: pointer;
        border: 4px solid transparent;
        &.disabled {
            cursor: not-allowed;
        }
        &:hover {
            border: 4px solid white;
        }
        &.selected {
            cursor: not-allowed;
            border: 4px solid $primary;
        }
        img {
            height: 100%;
        }
    }

    .profile-image-option {
        img,
        &:hover,
        &.selected {
            border-radius: 50% !important;
        }
        .delete-profile-image {
            top: 4px;
            right: 6px;
            position: absolute;
        }
    }
}
.refresh-option {
    border: 4px solid transparent;
    height: 100px;
    .refresh-icon {
        padding: 0 0.5rem;
        line-height: 104px;
        height: 100%;
        cursor: pointer;
        background-color: #4a4a4a;
        &:hover {
            background-color: #777;
        }
        i {
            font-size: 1.5rem;
        }
    }
}
@media only screen and (max-width: 600px) {
    .image-list {
        .thumbnail-option {
            height: 110px;
        }
    }
    .refresh-option {
        height: 110px;
        .refresh-icon {
            line-height: 114px;
        }
    }
    .label-emails {
        display: flex; // flex-direction: column;
        flex-wrap: wrap;
    }
}
@media (prefers-reduced-motion: reduce) {
    .fa-beat,
    .fa-bounce,
    .fa-fade,
    .fa-beat-fade,
    .fa-flip,
    .fa-pulse,
    .fa-shake,
    .fa-spin,
    .fa-spin-pulse {
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
}
.persona-list {
    .persona-thumbnail {
        position: relative;
        cursor: pointer;
        width: auto;
        height: 100px;
        border: 4px solid transparent;
        &.selected {
            cursor: not-allowed;
            border: 4px solid $primary;
            .persona-name {
                opacity: 1;
            }
        }
        img {
            height: 100%;
        }
        .persona-name {
            opacity: 0;
            padding: 5px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            font-size: 12px;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.5);
            div {
                max-height: 100%;
                overflow: hidden;
            }
        }
        &:hover {
            .persona-name {
                opacity: 1;
            }
        }
        .delete-persona {
            top: -3px;
            right: -5px;
            position: absolute;
        }
    }
}
.unset-border-radius {
    border-radius: 0;
}
.form-group {
    position: relative;
    .eye-icon {
        position: absolute;
        bottom: 7px;
        right: 12px;
    }
}
.phone-input {
    border: none !important;
    &.error {
        border-radius: 5px !important;
    }
    input {
        outline: none;
        border: 1px solid #ced4da;
        border-radius: 5px !important;
    }
    input:focus {
        outline: 0;
        border-color: #eea7ae;
        border-radius: 5px;
        box-shadow: 0 0 0 0.25rem rgba(221, 79, 93, 0.25);
    }
}
.label {
    color: #777;
}
.font-light-color {
    color: #aaa !important;
}
.animated-text {
    transition: opacity 2s;
}
.user-avatar .MuiAvatar-root.MuiAvatar-circular {
    background-color: white;
    text-transform: uppercase;
    font-style: normal;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}
.react-share__ShareButton {
    border-radius: 4px;
    overflow: hidden;
    opacity: 0.9;
    &:hover {
        opacity: 1;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    }
    .email-share-icon {
        rect {
            fill: $primary;
        }
    }
}
.empty-message {
    text-align: center;
    background: #3a3a3a;
    border-radius: 4px;
    svg {
        font-size: 100px;
    }
}
.grid-video-thumbnail {
    &.no-image {
        width: 100%;
        height: 150px;
        background: #555;
    }
    &.fixed-height {
        width: 100%;
        height: 150px;
        background-color: #444;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.8;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        &:hover {
            opacity: 1;
        }
    }
}
.video-upload-button {
    max-width: 100px;
    margin: 0 auto;
}
.video-viewed-progress-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    &.always-visible {
        background: #777;
    }
    .video-viewed-progress-bar {
        background: $primary;
        height: 3px;
    }
}
.play-button-overlay-container {
    position: absolute !important;
    background: rgba(255, 255, 255, 0.3);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
    .responses-count {
        font-weight: bold;
        margin-right: -4px;
        padding-left: 7px;
        font-size: 1.3rem;
        display: inline-block;
        vertical-align: middle;
    }
    &:hover {
        background: rgba(255, 255, 255, 0.5);
    }
}
.view-page-video-container {
    max-height: 600px;
    background: black;
}
.plan-duration-option {
    border-radius: 5px;
    background-color: #444444;
    height: 100%;
    cursor: pointer;
    &:hover {
        background-color: #494949;
    }
    .duration-label {
        text-transform: capitalize;
        font-size: 1.5rem;
    }
    .savings-badge {
        font-size: 1rem;
        padding: 5px;
        background: #508905;
        border-radius: 5px;
    }
}
.preview-drawer.MuiDrawer-root.MuiDrawer-modal {
    z-index: 1100;
    .MuiBackdrop-root.MuiModal-backdrop {
        backdrop-filter: blur(6px);
    }
    .MuiPaper-root.MuiPaper-elevation {
        overflow: visible;
        background-image: none;
        box-shadow: none;
    }
    .MuiBox-root {
        background-color: rgba(0, 0, 0, 0.6);
    }
}
.selected-reaction.MuiIconButton-root {
    background: #444;
}
.reaction-group {
    cursor: pointer;
    background: #444;
    color: white;
    border-radius: 22px;
    padding: 2px 10px;
    margin: 6px 0;
    border: 1px solid #444;
    &.selected {
        background: #555;
        border: 1px solid white;
    }
}
.video-preview {
    position: relative;
    .inbox-select {
        position: absolute;
        left: 2px;
        top: 2px;
    }
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    color: $primary;
}
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium :hover {
    opacity: 1;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}
.Mui-checked.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    color: $primary;
}
.transition-width {
    transition: width 300ms;
}
.transition-height {
    transition: height 300ms;
    overflow: hidden;
}
.transition-opacity {
    transition: opacity 500ms;
}
.transition-all {
    transition: all 500ms;
}
.plain-white-tabs,
.plain-tabs {
    width: 260px;
    min-width: 220px;
    position: relative;
    .tab-icon-container {
        color: white;
    }
    .emovid-tabs {
        position: absolute;
        top: -48px;
        width: 100%;
    }
    .MuiTabs-indicator {
        background-color: transparent !important;
    }
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
        font-size: 0.9rem;
    }
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
        text-decoration: underline;
        text-underline-offset: 6px;
        text-decoration-thickness: 3px;
        .tab-icon-container {
            border-bottom: 3px solid $primary;
            padding-bottom: 5px;
        }
        svg,
        svg path {
            fill: $primary;
        }
    }
    .video-preview {
        cursor: pointer;
    }
    .desktop-sidebar-container {
        height: calc(100vh - 200px);
        overflow-y: auto;
        overflow-x: hidden;
    }
}
.video-preview {
    &:hover {
        .view-page-video-thumbnail {
            opacity: 1;
        }
    }
    &.selected {
        cursor: not-allowed;
        .view-page-video-thumbnail {
            opacity: 1;
            border: 3px solid #fff;
        }
        .sender-label {
            bottom: 3px;
            width: calc(100% - 6px);
            left: 3px;
        }
        .private-video {
            top: 3px;
            width: calc(100% - 6px);
            left: 3px;
        }
    }
    .view-page-video-thumbnail {
        width: 100%;
        height: 136px;
        background-color: #444;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.8;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        &.no-image {
            background-size: 40px;
            background-position: 50% 30px;
        }
    }
    .mobile-view-page-video-thumbnail {
        position: relative;
        min-width: 160px;
        width: 160px;
        height: 100px;
        background-color: #444;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        &.selected {
            border: 5px solid #fff;
        }
        &.no-image {
            background-size: 40px;
        }
    }
    .private-video {
        top: 0;
        width: 100%;
        left: 0;
        font-size: 0.8rem;
        color: $primary-light;
        padding: 5px 10px;
        background: rgba(0, 0, 0, 0.6);
    }
    .sender-label {
        bottom: 0;
        width: 100%;
        left: 0;
        font-size: 0.8rem;
        text-align: center;
        padding: 5px;
        background: rgba(0, 0, 0, 0.4);
    }
}
.highlight-on-hover:hover {
    outline: white solid 2px;
}
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.close-button {
    z-index: 2;
}
.video-settings {
    .video-settings-label {
        position: absolute;
        left: 0;
        top: 10px;
        width: 100%;
        text-align: center;
    }
    .close-button {
        margin-left: auto;
    }
}
.privacy-settings {
    .privacy-settings-label {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 220px;
    }
    .close-button {
        margin-left: auto;
    }
}
.privacy-settings-controls {
    .controls {
        justify-content: unset;
        .password-control {
            padding: 0.5rem;
            width: 200px;
        }
        .delete-after-controls {
            width: 200px;
            input {
                padding: 0.5rem;
                max-width: 60px;
            }
            .delete-after-time-unit {
                width: 65%;
            }
        }
    }
    input {
        background: transparent;
        border: 1px solid #ced4da;
        border-radius: 5px !important;

        outline: none;
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 1; /* Firefox */
        }
    }
    input:focus {
        color: #212529;
        background-color: #fff;
        outline: 0;
        border-color: #eea7ae;
        border-radius: 5px;
        box-shadow: 0 0 0 0.25rem rgba(221, 79, 93, 0.25);
    }
}
.flip {
    -moz-transform: scaleX(-1); /* Gecko */
    -o-transform: scaleX(-1); /* Opera */
    -webkit-transform: scaleX(-1); /* Webkit */
    transform: scaleX(-1); /* Standard */
    filter: FlipH; /* IE 6/7/8 */
}
.alert-icon {
    max-width: 117px;
    margin: 0 auto;
}

.plain-white-tabs {
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
        color: white !important;
    }
}
.circular-cutout-overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    box-shadow: 0px 0px 0px 2000px rgba(255, 255, 255, 0.4);
    z-index: 10;
}
.persona-head-movement {
    position: absolute;
    height: 250px;
    width: 250px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    img {
        width: 100%;
        opacity: 0.4;
    }
}
@media only screen and (min-width: 600px) {
    .persona-head-movement {
        height: 300px;
        width: 300px;
    }
}
.circular-persona-record-timer {
    position: absolute;
    left: 25%;
    top: 0;
    z-index: 12;
}
.drawer-tabs button.MuiButtonBase-root.MuiTab-root {
    font-weight: normal;
    font-size: 0.9rem;
    padding-bottom: 4px;
}
.chatbox-tabs-container {
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}
.masked-bottom {
    -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}
.response-previews-container {
    width: 100%;
}
.menu-text {
    font-size: 0.8rem;
}
.dashboard-section-filters {
    margin-left: auto;
}
.close-drawer-btn {
    position: absolute;
    bottom: -130px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 500%;
    width: 50px;
    height: 50px;
    color: white;
    &.top {
        bottom: unset;
        top: -120px;
    }
    .MuiSvgIcon-root {
        margin: 13px;
    }
}
.recipients-summary {
    cursor: pointer;
    .more-members {
        background: #777;
        padding: 4px;
        border-radius: 4px;
        margin-left: 5px;
    }
}
.created-emovids-video-dialog,
.created-emovids-views-dialog {
    background: rgb(53, 53, 53);
    border-radius: 5px;
    color: white;
    padding: 16px;
}
.video-player-dialog .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background: rgb(53, 53, 53);
}
.created-emovids-views-dialog {
    padding: 16px 0 16px 16px;
}
.created-emovids-video-dialog-recipients {
    max-height: 4rem;
    overflow-y: scroll;
}
.created-emovids-table {
    table-layout: fixed;
    .views {
        text-align: center;
    }
    .date {
        text-align: center;
    }
    .MuiTableCell-root {
        border-bottom: none;
    }
}
th.table-header {
    font-weight: bold;
    background: #4f4f4f;
}
td.MuiTableCell-root {
    border-bottom: 1px solid #777;
}
ol,
ul,
dl {
    &.list-top-aligned {
        margin-bottom: 0;
        display: table;
        padding-left: 1.5rem;
    }
}
.border-left {
    border-left: 1px solid #777;
}
.created-emovids-table-head {
    .lock {
        max-width: 41px;
    }
    .recipient {
        max-width: 180px;
    }
    .subject {
        max-width: 250px;
    }
    .summary {
        max-width: 380px;
    }
    .date {
        max-width: 125px;
    }
    .views {
        max-width: 75px;
    }
    .MuiTableCell-head {
        font-size: 1rem;
    }
}
.created-emovid-row {
    cursor: pointer;
    &.offline-video .play-button {
        background-color: $primary;
        padding: 3px 13px;
        &.bg-transparent {
            background-color: #777;
        }
    }
}
.emovid-row-hover:hover td {
    background-color: rgba(255, 255, 255, 0.08);
}
.emovid-row-hover:hover td:last-of-type,
.emovid-row-hover:hover td:first-of-type,
.emovid-row-hover:hover td:nth-of-type(2),
.emovid-row-hover:hover td:nth-last-of-type(2) {
    background-color: transparent;
}
.persona {
    .example-personas-text {
        font-size: 1.2rem;
    }
    .persona-container {
        overflow: hidden;
        color: #333;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media only screen and (min-width: 576px) {
            .persona-thumbs {
                background-color: #444;
                max-width: 250px;
                width: 100%;
                margin: auto;
                height: 125px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    .creator-details {
        font-size: 0.8rem;
        color: #999;
    }
}
.created-emovids-table-body {
    .play-button {
        cursor: pointer;
        max-width: 50px;
        height: 30px;
        width: 50px;
        object-fit: contain;
        background: #777;
        border-radius: 5px;
        margin: auto;
        color: $primary;
        display: block;
    }
    .link {
        color: white;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }
    .MuiTableCell-body {
        color: #999;
        font-size: 0.8rem;
        padding: 16px 4px;
    }
    .recipient {
        color: white;
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        .show-on-hover {
            opacity: 0;
        }
        &:hover .show-on-hover {
            opacity: 1;
        }
    }
    .subject {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
    }
    .summary {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
    .date {
        color: white;
    }
    .views {
        color: white;
    }
    .misc-line {
        align-items: center;
    }
    .divider {
        margin-bottom: 16px;
    }
}
@media (max-width: 800px) {
    .created-emovids-table-body {
        .play-button {
            max-width: 60px;
            height: 36px;
            width: 60px;
        }
    }
}
@media (max-width: 600px) {
    .created-emovids-table-body {
        .MuiTableCell-body {
            padding: 16px 4px 6px;
        }
    }
}
.post-views-table-head {
    .MuiSvgIcon-root.MuiTableSortLabel-icon {
        opacity: 0.3;
    }
}
#prefinery_popup {
    background-color: #333 !important;
    top: calc(1% + env(safe-area-inset-top)) !important;
}
#prefinery_close {
    color: #fff !important;
    opacity: 0.8 !important;
    &:hover {
        opacity: 1 !important;
    }
}
.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}
[data-testid="engagement-nudge-tooltip-icon"] {
    color: white;
    svg {
        color: white;
    }
}
.grid-item {
    /* change div to reference your elements you put in <Masonry> */
    background: #1a1a1a;
    margin-bottom: 30px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    // &.unviewed {
    //     background: #3a3a3a;
    // }
    &.highlighted {
        border: 3px solid $primary;
    }
    .post-flags-indicator,
    .post-members-indicator {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 0;
        padding: 5px;
        border-radius: 5px 0 5px 0;
        font-size: 14px;
        filter: drop-shadow(1px 1px 2px rgb(0, 0, 0));
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
            font-size: 16px;
        }
    }
    .post-members-indicator {
        left: unset;
        right: 0;
        border-radius: 0 5px 0 5px;
    }
    .responses-container {
        position: relative;
        max-height: 408px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 1rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .thumbnail-container {
        display: block;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        min-height: 100px;
        cursor: pointer;
        img {
            opacity: 0.8;
        }
        &:hover {
            img {
                opacity: 1;
            }
            .grid-video-thumbnail.fixed-height {
                opacity: 1;
            }
        }
    }
    img {
        width: 100%;
    }
    .post-details {
        font-size: 0.9rem;
    }
    .link {
        color: white;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }
    .creator-details {
        font-size: 0.8rem;
        color: #999;
    }
}
/* Optional, different gutter size on mobile */
@media (max-width: 800px) {
    .my-masonry-grid {
        margin-left: -15px; /* gutter size offset */
    }
    .my-masonry-grid_column {
        padding-left: 15px; /* gutter size offset */
    }
    .my-masonry-grid_column > .grid-item {
        margin-bottom: 25px; /* space between items */
    }
}
@media only screen and (max-width: 600px) {
    .suggestion-button {
        font-size: 0.9rem;
    }
}
@media only screen and (max-width: 400px) {
    .social-buttons > button {
        padding: 0.25rem !important;
    }
}
::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    cursor: pointer;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
    cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}
.editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 8px;
}
.editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 8px;
}
.editor-listitem {
    margin: 8px 32px 8px 32px;
    list-style-position: inside;
}
.editor-container {
    position: relative;
    width: 100%;
    color: #fff;
    .other h2 {
        font-size: 18px;
        color: #444;
        margin-bottom: 7px;
    }
    .other a {
        color: #777;
        text-decoration: underline;
        font-size: 14px;
    }
    .other ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .editor-inner {
        position: relative;
        max-height: 200px;
        overflow: auto;
        &.suggestions-editor {
            min-height: 340px;
            max-height: 375px;
            .editor-input {
                min-height: 340px;
            }
        }
        &.notes-editor {
            min-height: 120px;
            max-height: 120px;
            .editor-input {
                min-height: 120px;
            }
            transition: all 400ms ease-in-out;
        }
        &.notes-editor-fullscreen {
            min-height: 425px;
            max-height: 425px;
            transition: all 400ms ease-in-out;
            .editor-input {
                min-height: 400px;
            }
        }
        &:hover {
            .editor-placeholder {
                color: white;
            }
        }
    }
    .editor-input {
        resize: none;
        font-size: 15px;
        caret-color: rgb(255, 255, 255);
        position: relative;
        tab-size: 1;
        outline: 0;
        padding: 15px 10px;
        min-height: 52px;
    }
    .editor-placeholder {
        color: #aaa;
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: 15px;
        left: 10px;
        font-size: 15px;
        user-select: none;
        display: inline-block;
        pointer-events: none;
    }
    .editor-text-bold {
        font-weight: bold;
    }
    .editor-text-italic {
        font-style: italic;
    }
    .editor-text-underline {
        text-decoration: underline;
    }
    .editor-text-strikethrough {
        text-decoration: line-through;
    }
    .editor-text-underlineStrikethrough {
        text-decoration: underline line-through;
    }
    .editor-text-code {
        background-color: rgb(240, 242, 245);
        padding: 1px 0.25rem;
        font-family: Menlo, Consolas, Monaco, monospace;
        font-size: 94%;
        caret-color: #333;
    }
    .editor-link {
        text-decoration: underline;
        color: $primary;
    }
    .editor-code {
        background-color: rgb(240, 242, 245);
        font-family: Menlo, Consolas, Monaco, monospace;
        display: block;
        padding: 8px 8px 8px 52px;
        line-height: 1.53;
        font-size: 13px;
        margin: 0;
        margin-top: 8px;
        margin-bottom: 8px;
        tab-size: 2;
        /* white-space: pre; */
        overflow-x: auto;
        position: relative;
    }
    .editor-code:before {
        content: attr(data-gutter);
        position: absolute;
        background-color: #eee;
        left: 0;
        top: 0;
        border-right: 1px solid #ccc;
        padding: 8px;
        color: #777;
        white-space: pre-wrap;
        text-align: right;
        min-width: 25px;
    }
    .editor-code:after {
        content: attr(data-highlight-language);
        top: 0;
        right: 3px;
        padding: 3px;
        font-size: 10px;
        text-transform: uppercase;
        position: absolute;
        color: rgba(0, 0, 0, 0.5);
    }
    .editor-tokenComment {
        color: slategray;
    }
    .editor-tokenPunctuation {
        color: #999;
    }
    .editor-tokenProperty {
        color: #905;
    }
    .editor-tokenSelector {
        color: #690;
    }
    .editor-tokenOperator {
        color: #9a6e3a;
    }
    .editor-tokenAttr {
        color: #07a;
    }
    .editor-tokenVariable {
        color: #e90;
    }
    .editor-tokenFunction {
        color: #dd4a68;
    }
    .editor-paragraph {
        margin: 0;
        position: relative;
    }
    .editor-heading-h1 {
        font-size: 24px;
        color: white;
        font-weight: 400;
        margin: 0;
        margin-bottom: 12px;
        padding: 0;
    }
    .editor-heading-h2 {
        font-size: 15px;
        color: white;
        font-weight: 700;
        margin: 0;
        margin-top: 10px;
        padding: 0;
    }
    .editor-quote {
        margin: 0;
        margin-left: 20px;
        font-size: 15px;
        color: white;
        border-left-color: rgb(206, 208, 212);
        border-left-width: 4px;
        border-left-style: solid;
        padding-left: 16px;
    }
    .editor-nested-listitem {
        list-style-type: none;
    }
    pre::-webkit-scrollbar {
        background: transparent;
        width: 10px;
    }
    pre::-webkit-scrollbar-thumb {
        background: #999;
        cursor: pointer;
    }
    .debug-timetravel-panel {
        overflow: hidden;
        padding: 0 0 10px 0;
        margin: auto;
        display: flex;
    }
    .debug-timetravel-panel-slider {
        padding: 0;
        flex: 8;
    }
    .debug-timetravel-panel-button {
        padding: 0;
        border: 0;
        background: none;
        flex: 1;
        color: #fff;
        font-size: 12px;
    }
    .debug-timetravel-panel-button:hover {
        text-decoration: underline;
    }
    .debug-timetravel-button {
        border: 0;
        padding: 0;
        font-size: 12px;
        top: 10px;
        right: 15px;
        position: absolute;
        background: none;
        color: #fff;
    }
    .debug-timetravel-button:hover {
        text-decoration: underline;
    }
    .emoji {
        color: transparent;
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin: 0 -1px;
    }
    .emoji-inner {
        padding: 0 0.15em;
    }
    .emoji-inner::selection {
        color: transparent;
        background-color: rgba(150, 150, 150, 0.4);
    }
    .emoji-inner::moz-selection {
        color: transparent;
        background-color: rgba(150, 150, 150, 0.4);
    }
    .emoji.happysmile {
        background-image: url("./../public/editor/emoji/1F642.png");
    }
    .toolbar {
        display: flex;
        gap: 5px;
        padding: 10px 0;
        overflow: auto;
        vertical-align: middle;
        justify-content: center;
    }
    .toolbar button.toolbar-item {
        border: 0;
        display: flex;
        color: #fff;
        background: transparent;
        border-radius: 10px;
        padding: 5px 5px 2px;
        cursor: pointer;
        vertical-align: middle;
        .toolbar-button-icon {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
    .toolbar button.toolbar-item:disabled {
        cursor: not-allowed;
        color: #555;
    }
    .toolbar button.toolbar-item.spaced {
        margin-right: 2px;
    }
    .toolbar button.toolbar-item i.format {
        background-size: contain;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-top: 2px;
        vertical-align: -0.25em;
        display: flex;
        opacity: 0.6;
    }
    .toolbar button.toolbar-item:disabled i.format {
        opacity: 0.2;
    }
    .toolbar button.toolbar-item.active {
        color: $primary;
    }
    .toolbar button.toolbar-item.active i {
        opacity: 1;
    }
    .toolbar .toolbar-item:hover:not([disabled]) {
        background-color: #777;
    }
    .toolbar .divider {
        width: 1px;
        background-color: #777;
    }
    .toolbar select.toolbar-item {
        border: 0;
        display: flex;
        background: none;
        border-radius: 10px;
        padding: 8px;
        vertical-align: middle;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 70px;
        font-size: 14px;
        text-overflow: ellipsis;
    }
    .toolbar select.code-language {
        text-transform: capitalize;
        width: 130px;
        background-color: white;
        padding: 0 8px;
        appearance: menulist;
        -webkit-appearance: menulist;
        -moz-appearance: revert;
    }

    .toolbar .toolbar-item .text {
        display: flex;
        line-height: 20px;
        width: 200px;
        vertical-align: middle;
        font-size: 14px;
        text-overflow: ellipsis;
        width: 70px;
        overflow: hidden;
        height: 20px;
        text-align: left;
    }

    .toolbar .toolbar-item .icon {
        display: flex;
        width: 20px;
        height: 20px;
        user-select: none;
        margin-right: 8px;
        line-height: 16px;
        background-size: contain;
    }

    .toolbar i.chevron-down {
        margin-top: 3px;
        width: 16px;
        height: 16px;
        display: flex;
        user-select: none;
    }

    .toolbar i.chevron-down.inside {
        width: 16px;
        height: 16px;
        display: flex;
        margin-left: -25px;
        margin-top: 8px;
        margin-right: 10px;
        pointer-events: none;
    }

    i.chevron-down {
        background-color: transparent;
        background-size: contain;
        display: inline-block;
        height: 8px;
        width: 8px;
        background-image: url(../public/editor/icons/chevron-down.svg);
    }

    .icon.block-type.h1,
    .icon.block-type.h2 {
        margin-bottom: 0;
    }

    #block-controls button:hover {
        background-color: #efefef;
    }

    #block-controls button:focus-visible {
        border-color: blue;
    }

    #block-controls span.block-type {
        background-size: contain;
        display: block;
        width: 18px;
        height: 18px;
        margin: 2px;
    }

    #block-controls span.block-type.paragraph {
        background-image: url(../public/editor/icons/text-paragraph.svg);
    }

    #block-controls span.block-type.h1 {
        background-image: url(../public/editor/icons/type-h1.svg);
    }

    #block-controls span.block-type.h2 {
        background-image: url(../public/editor/icons/type-h2.svg);
    }

    #block-controls span.block-type.quote {
        background-image: url(../public/editor/icons/chat-square-quote.svg);
    }

    #block-controls span.block-type.ul {
        background-image: url(../public/editor/icons/list-ul.svg);
    }

    #block-controls span.block-type.ol {
        background-image: url(../public/editor/icons/list-ol.svg);
    }

    #block-controls span.block-type.code {
        background-image: url(../public/editor/icons/code.svg);
    }

    i.undo {
        background-image: url(../public/editor/icons/arrow-counterclockwise.svg);
    }

    i.redo {
        background-image: url(../public/editor/icons/arrow-clockwise.svg);
    }

    .icon.paragraph {
        background-image: url(../public/editor/icons/text-paragraph.svg);
    }

    .icon.large-heading,
    .icon.h1 {
        background-image: url(../public/editor/icons/type-h1.svg);
    }

    .icon.small-heading,
    .icon.h2 {
        background-image: url(../public/editor/icons/type-h2.svg);
    }

    .icon.bullet-list,
    .icon.ul {
        background-image: url(../public/editor/icons/list-ul.svg);
    }

    .icon.numbered-list,
    .icon.ol {
        background-image: url(../public/editor/icons/list-ol.svg);
    }

    .icon.quote {
        background-image: url(../public/editor/icons/chat-square-quote.svg);
    }

    .icon.code {
        background-image: url(../public/editor/icons/code.svg);
    }

    i.bold {
        background-image: url(../public/editor/icons/type-bold.svg);
    }

    i.italic {
        background-image: url(../public/editor/icons/type-italic.svg);
    }

    i.underline {
        background-image: url(../public/editor/icons/type-underline.svg);
    }

    i.strikethrough {
        background-image: url(../public/editor/icons/type-strikethrough.svg);
    }

    i.code {
        background-image: url(../public/editor/icons/code.svg);
    }

    i.link {
        background-image: url(../public/editor/icons/link.svg);
    }

    i.left-align {
        background-image: url(../public/editor/icons/text-left.svg);
    }

    i.center-align {
        background-image: url(../public/editor/icons/text-center.svg);
    }

    i.right-align {
        background-image: url(../public/editor/icons/text-right.svg);
    }

    i.justify-align {
        background-image: url(../public/editor/icons/justify.svg);
    }
}
@media only screen and (max-width: 768px) {
    .editor-container {
        .editor-inner {
            &.suggestions-editor {
                min-height: auto;
                max-height: unset;
                .editor-input {
                    min-height: 52px;
                }
            }
            &.notes-editor {
                min-height: auto;
                max-height: unset;
                .editor-input {
                    min-height: 52px;
                }
            }
        }
    }
}
.dropdown {
    z-index: 1191;
    display: block;
    position: absolute;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    min-width: 100px;
    min-height: 40px;
    background-color: #fff;
}

.dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    min-width: 268px;
}

.dropdown .item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.dropdown .item:first-child {
    margin-top: 8px;
}

.dropdown .item:last-child {
    margin-bottom: 8px;
}

.dropdown .item:hover {
    background-color: #eee;
}

.dropdown .item .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    width: 200px;
}

.dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
}
.editor-toolbar-container {
    transition: all 400ms;
    overflow: hidden;
    &.top-divider {
        border-top: 1px solid #555;
    }
}
.link-editor {
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 400px;
    width: calc(100% - 40px);
    opacity: 0;
    background-color: #4a4a4a;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 10px;
    transition: opacity 0.5s;
    .link-input {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 4px 12px;
        border-radius: 4px;
        background-color: #eee;
        font-size: 15px;
        color: rgb(5, 5, 5);
        border: none;
        outline: none;
        position: relative;
        font-family: inherit;
        a {
            color: rgb(33, 111, 219);
            text-decoration: none;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            margin-right: 30px;
            text-overflow: ellipsis;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .button {
        width: 20px;
        height: 20px;
        display: inline-block;
        padding: 6px;
        border-radius: 8px;
        cursor: pointer;
        margin: 0 2px;
        &.hovered {
            background-color: #eee;
        }
    }
}
.share-button {
    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        img {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            filter: grayscale(100%);
        }
    }
}
.search-container {
    display: flex;
    align-items: center;
}
.search-box {
    display: flex;
    min-width: 45% !important;
    position: relative;
    .search-input {
        width: 100%;
    }
    input {
        border-radius: 1.375rem;
        padding-left: 42px;
        padding-right: 50px;
    }
    .left-icons {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .right-icons {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    &.mobile {
        min-width: 100% !important;
    }
}
.search-menu {
    .search-in {
        padding: 0.5rem 1rem 0rem 1rem;
    }
    .include-item {
        padding-top: 0;
        padding-bottom: 0;
    }
    .search-in-checkboxes {
        width: 100%;
    }
    .select-box {
        label {
            min-width: 100px;
        }
        select {
            border-radius: 1.375rem;
        }
        select,
        input {
            padding: 0.5rem;
            border: 1px solid #777;
            background-color: #333;
            color: white;
            font-size: 0.9rem;
            cursor: pointer;
        }
        input {
            max-width: 56px;
            margin-left: 10px;
        }
    }
}
.grecaptcha-badge {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 60px 40px;
    -webkit-transform-origin: 60px 40px;
}
.toggleContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: fit-content;
    border: 3px solid #666666;
    border-radius: 36px;
    background: #666666;
    color: #666666;
    cursor: pointer;
}
.toggleContainer::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0%;
    border-radius: 36px;
    background: $primary;
    transition: all 0.3s;
}
.toggleCheckbox:checked + .toggleContainer::before {
    left: 50%;
}
.toggleContainer div {
    padding: 6px;
    text-align: center;
    z-index: 1;
}
.toggleCheckbox {
    display: none;
}
.toggleCheckbox:checked + .toggleContainer div:first-child {
    color: white;
    transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer div:last-child {
    color: white;
    transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:first-child {
    color: white;
    transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:last-child {
    color: white;
    transition: color 0.3s;
}
.usage-option {
    border: 1px solid #505050;
    border-radius: 5px;
    height: 100%;
    font-size: 0.9rem;
    cursor: pointer;
    .title {
        color: rgba(255, 255, 255, 0.7);
    }
    .description {
        color: rgba(255, 255, 255, 0.3);
    }
    &:hover {
        border: 1px solid #777777;
        .title {
            color: #fff;
        }
        .description {
            color: rgba(255, 255, 255, 0.5);
        }
    }
}

.link-editor .button i,
.actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
}
.flash-once {
    -webkit-animation: flash linear 1s 1;
    animation: flash linear 1s 1;
}
.flash-twice {
    -webkit-animation: flash linear 1s 2;
    animation: flash linear 1s 2;
}
.flash-bg-infinite {
    animation: flash-bg ease-in-out 1s infinite;
}
.language-select {
    border-color: transparent;
    color: white;
}
.language-select::after {
    border-bottom-color: white !important;
}
.more-popup {
    .language-list {
        min-width: 250px;
        overflow-y: auto;
        margin-top: 60px;
    }
    .Mui-selected {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }
}
.search-match {
    padding: 0 2px;
    border: 1px solid yellow;
    border-radius: 3px;
}
.current-match {
    border: 1px solid orange;
    background: orange;
    color: white;
}
.icon.color-picker-icon {
    background-position: center;
    background-repeat: no-repeat;
}
.home-page-slider-section {
    height: calc(100vh - env(safe-area-inset-top));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .slider-container {
        width: 100vw;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: space-between;
    }

    .ipad-message {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary;
        height: calc(100vh - env(safe-area-inset-top));
        font-weight: bold;
        font-size: 1.2rem;
    }

    .home-page-image-slide {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .home-page-image {
            width: 100%;
            object-fit: contain;
        }
    }
    .indicators {
        padding-left: 0px;
        margin-bottom: 0.75rem;
    }
    .sign-in-button {
        display: flex;
        justify-content: center;
    }
}
.translation-set {
    font-size: 0.9rem;
}
.rtl {
    direction: rtl;
}
.icon.font-color {
    background-image: url(../public/editor/icons/font-color.svg);
}
.icon.bg-color {
    background-image: url(../public/editor/icons/bg-color.svg);
}
.color-picker-wrapper {
    padding: 20px;
    background: #484848;
    .color-picker-basic-color {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 0;
        padding: 0;
    }
    .color-picker-basic-color button {
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 16px;
        width: 16px;
        cursor: pointer;
        list-style-type: none;
    }
    .color-picker-basic-color button.active {
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
    }
    .current-color {
        margin-top: 5px;
        text-align: center;
    }
    .clear-color-button {
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.75em' x='0.05em' font-size='100'>⃠</text></svg>");
    }
    .color-picker-saturation {
        width: 100%;
        position: relative;
        margin-top: 15px;
        height: 150px;
        background-image: linear-gradient(transparent, black), linear-gradient(to right, white, transparent);
        user-select: none;
    }
    .color-picker-saturation_cursor {
        position: absolute;
        width: 20px;
        height: 20px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        box-shadow: 0 0 15px #00000026;
        box-sizing: border-box;
        transform: translate(-10px, -10px);
    }
    .color-picker-hue {
        width: 100%;
        position: relative;
        margin-top: 15px;
        height: 12px;
        background-image: linear-gradient(to right, rgb(255, 0, 0), rgb(255, 255, 0), rgb(0, 255, 0), rgb(0, 255, 255), rgb(0, 0, 255), rgb(255, 0, 255), rgb(255, 0, 0));
        user-select: none;
        border-radius: 12px;
    }
    .color-picker-hue_cursor {
        position: absolute;
        width: 20px;
        height: 20px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        box-shadow: #0003 0 0 0 0.5px;
        box-sizing: border-box;
        transform: translate(-10px, -4px);
    }
    .color-picker-color {
        border: 1px solid #ccc;
        margin-top: 5px;
        width: 100%;
        height: 20px;
    }
}
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #aaa;
    color: #aaa;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1s infinite linear;
    animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #aaa;
    color: #aaa;
}
.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1s infinite linear;
    animation-delay: 0s;
}
.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1s infinite linear;
    animation-delay: 0.5s;
}
@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }
    30% {
        box-shadow: 9984px 0 0 2px;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px;
    }
}
@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }
    30% {
        box-shadow: 9999px 0 0 2px;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px;
    }
}
@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }
    30% {
        box-shadow: 10014px 0 0 2px;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px;
    }
}
@-webkit-keyframes flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes flash-bg {
    0% {
        background: rgba(0, 0, 0, 0.3);
    }
    50% {
        background: rgba(180, 180, 180, 0.7);
    }
    100% {
        background: rgba(0, 0, 0, 0.3);
    }
}
@keyframes flash-bg {
    0% {
        background: rgba(0, 0, 0, 0.3);
    }
    50% {
        background: rgba(180, 180, 180, 0.7);
    }
    100% {
        background: rgba(0, 0, 0, 0.3);
    }
}
